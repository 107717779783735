html {
  --color-white: #fff;
  --color-text: #1a1b31;
  --color-primary: #e04e0c;
  --font-main-family: 'Work Sans', -apple-system, sans-serif;
  --font-default-size: 16px;
}

::-webkit-scrollbar {
  display: none;
}

body {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  margin: 0;
  min-height: 100vh;

  font-family: var(--font-main-family);
  font-weight: 400;
  font-size: var(--font-default-size);
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-white);
  color: var(--color-text);
}

#root {
  display: flex;
  flex-flow: column nowrap;

  flex: 1 1 100%;
  min-height: 100vh;
  max-height: 100vh;
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

input,
textarea,
button {
  font-family: inherit;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0.4;
  }

  60% {
    transform: scale(0.9);
    opacity: 0.1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}
