.Toastify__toast {
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 32rem;
  width: auto;
}

.Toastify__toast-body {
  padding: 0;
  padding-bottom: 0.5rem;
  margin-right: 0.5rem;
}
